import React, { createContext, useContext, useEffect, useState } from 'react';
import useWordPressLogin from '../hooks/useLoginWordpressApi';
// import { useAppSelector } from 'react-redux';
import { useUserDetailsQuery } from '../features/api';
import useAuthenticated from '../hooks/useAuthenticated';

interface WordPressContextProps {
  loading: boolean;
  error: string | null;
  result: any | null;
  loginToWordPress: (userDetails: any, token: string) => Promise<any | null>;
  userDetails: any | null;
  wpLogin:any
}

const WordPressContext = createContext<WordPressContextProps | undefined>(undefined);

export const WordPressProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { loginToWordPress, loading, error, result } = useWordPressLogin();
  const authenticated = useAuthenticated();
  const { data: userdetails,isLoading,refetch } = useUserDetailsQuery({});
  const [userDetails,setUserDetails] = useState<any>({})

  useEffect(() => {
    if(typeof userdetails !== 'undefined'){
      setUserDetails(userdetails)
    }
  },[userdetails])

  useEffect(() => {
    if (Object.keys(userDetails).length > 0 && authenticated.token !== '' && userDetails.error !== true) {
      loginToWordPress(userDetails, authenticated.token);
    }
  }, [userDetails, authenticated.token.length >0]);

  const wpLogin = (user:any,token:any) => {
    if (user && token) {
      loginToWordPress(user, token);
    }
  };

  return (
    <WordPressContext.Provider
      value={{
        loading,
        error,
        result,
        loginToWordPress,
        userDetails,
        wpLogin
      }}
    >
      <>
      {children}
      </>

    </WordPressContext.Provider>
  );
};

export const useWordPressContext = () => {
  const context = useContext(WordPressContext);
  if (!context) {
    throw new Error('useWordPressContext must be used within a WordPressProvider');
  }
  return context;
};
