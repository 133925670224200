import { createSlice } from '@reduxjs/toolkit';

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState: {
    items: [],
  },
  reducers: {
    addToWishlistItem: (state:any, action:any) => {
      const item:any = action.payload;
      // Avoid adding duplicate items
      if (!state.items.find((i:any) => (i.id === item.id && i.dataType === item.dataType))) {
        state.items.push(item);
      }
    },
    removeFromWishlist: (state, action) => {
      const item = action.payload;
      state.items = state.items.filter((i:any) => !(i.id === item.id && i.dataType === item.dataType));
    },
    removespecificlist:(state,action:any) => {
      state.items = state.items.filter((i:any) => !(i.dataType === action.payload.dataType));
    },
    clearWishlist: (state) => {
      state.items = [];
    },
  },
});

export const { addToWishlistItem, removeFromWishlist,removespecificlist, clearWishlist } = wishlistSlice.actions;

export default wishlistSlice.reducer;
