/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
// import { Modal } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import TicketDetails from './TicketDetails';
import TicketList from './TicketList';
import { useTranslation } from 'react-i18next';
import { Steps } from 'intro.js-react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faSmile } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../features/hooks';
import { useDispatch } from 'react-redux';
import { fetchUserDetails } from '../features/userDetailsSlice';
import Loader from './Loader';
import axios from 'axios';
import { fetchSupportCustomer } from '../features/getSupportCustomer';
import { fetchSupportStatus } from '../features/getSupportStatus';
import { fetchSupportCategory } from '../features/getSupportCategory';
import { fetchSupportTickets } from '../features/getSupportTickets';
import { toast } from 'react-toastify';
import { useWordPressContext } from '../context/WordpressContext';
import { Link } from 'react-router-dom';
import { fetchData } from '../features/projectSlice';
import ErrorPage from './ErrorPage';

const options = [
  { label: 'Select Subject 01', value: 'sub1' },
  { label: 'Select Subject 02', value: 'sub2' },
  { label: 'Select Subject 03', value: 'sub3' },
];

const Suports: React.FC = () => {
  const [ticketId, setTicketId] = useState('');
  const [showModal, setShow] = useState<boolean>(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(!showModal);
  const [selected, setSelected] = useState([]);
  const [fileUpload, setFileUpload] = useState(false);

  const [stepsEnabled, setStepsEnabled] = useState<any>(null);
  const [filename, setFilename] = useState<string>('');
  const [file, setFile] = useState<any>(null);
  const [listFile, setListFile] = useState<Array<any>>([]);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const pathname = window.location.pathname;
  const dispatch = useDispatch();
  const [ticketData, setTicketData] = useState<Array<any>>([]);
  const [wordpressLoginUrl, setWordpressLoginUrl] = useState<any>({});
  const [wordpressLinkLoader, setWordpressLinkLoader] = useState<boolean>(true);
  const token = localStorage.getItem('token') || '';
  const {result, loading, error} = useWordPressContext()
  const [supportCategoryList,setSupportCategoryList] = useState<Array<any>>([])
  const [supportCateId,setSupportCateId] = useState(0)
  const [projectEnv, setProjectEnv] = useState<any>({});
  const [selectedEnv, setSelectedEnv] = useState<any>({});
  const [projects, setProjects] = useState<Array<any>>([]);
  const [healthInfo, setHealthInfo] = useState('')
  const { data: projectsData, isLoading, isError } = useAppSelector((state: any) => state.projects);
  const [params] = useSearchParams()
  const [projectId,setProjectId] = useState<number>(0)
  const [envId,setEnvId] = useState<number>(0)
  const [supportToggle,setSupportToggle] = useState<boolean>(false)
  console.log('params',)
  // const selector =useSelector();
  const [formError, setFormError] = useState<any>({});
  const [userDetails, setUserDetails] = useState<any>({
    name: '',
    email: '',
    subject: '',
    description: '',
    description_attachments: '',
  });

  useEffect(() => {
    dispatch(fetchUserDetails());
    dispatch(fetchData());
    // dispatch(fetchSupportCategory());
    // dispatch(fetchSupportCustomer());
    // dispatch(fetchSupportStatus());
  }, [dispatch]);

  const { data: userdetails } = useAppSelector((state: any) => state.userDetails);

  useEffect(() => {
    if (projectsData !== null) {
      setProjects(
        Object.prototype.hasOwnProperty.call(projectsData, 'projects') ? projectsData.projects : projectsData
      );
    }
  }, [projectsData]);

  useEffect(() => {
    if (userdetails !== null && result !== null) {
      const fetchLoginUrl = async () => {
        try {
          // const url = (await loginToWordPress()) || '';
          // localStorage.setItem('wordpressloginurl', url);
          if(result.success === true){
            setWordpressLoginUrl(result);
          }
        } catch (error) {
          setWordpressLoginUrl({});
          console.error('Error fetching login URL:', error);
        }
      };

      fetchLoginUrl();
    }
  }, [userdetails, result]);

  // const loginToWordPress = async (): Promise<string | null> => {
  //   try {
  //     const formData = new FormData();
  //     formData.append('Email', userdetails.email);
  //     formData.append('token', token);
  //     // formData.append("Password",atob(data.password))
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp-json/signin-user/myaccount`,
  //       formData,
  //       {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       }
  //     );
  //     const redirectUrl = response.data;
  //     setWordpressLinkLoader(false);
  //     if (Object.prototype.hasOwnProperty.call(redirectUrl, 'success')) {
  //       if (redirectUrl.success === false) {
  //         const email = userdetails.email;
  //         const atIndex = email.indexOf('@');
  //         if (atIndex !== -1) {
  //           const emailBeforeAt = email.substring(0, atIndex);
  //           axios({
  //             url: `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp/user-sync.php`,
  //             method: 'POST',
  //             headers: {},
  //             params: {
  //               action: 'get_user_data',
  //               fname: userdetails.displayName,
  //               lname: userdetails.displayName,
  //               username: emailBeforeAt,
  //               email: userdetails.email,
  //               role: 'customer',
  //             },
  //             data: {},
  //           })
  //             .then(res1 => {
  //               console.log('res1', res1);
  //               setWordpressLinkLoader(false);
  //             })
  //             .catch((err1: any) => {
  //               console.log('err1', err1);
  //             });
  //         }
  //         setWordpressLinkLoader(false);
  //         return redirectUrl;
  //       } else {
  //         setWordpressLinkLoader(false);
  //         return redirectUrl;
  //       }
  //     } else {
  //       return redirectUrl;
  //     }
  //   } catch (error) {
  //     console.error('Error during login:', error);
  //     return null;
  //   }
  // };

  useEffect(() => {
    if(Object.keys(wordpressLoginUrl).length > 0){
      dispatch(fetchSupportCategory(wordpressLoginUrl));
      dispatch(fetchSupportCustomer(wordpressLoginUrl));
      dispatch(fetchSupportStatus(wordpressLoginUrl));
    }
  },[wordpressLoginUrl])


  const { data: supportStatus } = useAppSelector((state: any) => state.supportStatus);
  const { data: supportCategory } = useAppSelector((state: any) => state.supportCategory);
  const { data: supportCustomers } = useAppSelector((state: any) => state.supportCustomers);
  const { data: supportTickets } = useAppSelector((state: any) => state.supportTickets);

  // console.log("supportCategory",supportCategory)

  useEffect(() => {
    if (userdetails !== null && Object.keys(wordpressLoginUrl).length > 0) {
      dispatch(fetchSupportTickets({...wordpressLoginUrl,email:userdetails.email}));
    }
  }, [dispatch, userdetails,wordpressLoginUrl]);


  useEffect(() => {
    if (userdetails !== null) {
      setUserDetails({
        name: userdetails.name,
        email: userdetails.email,
        subject: '',
        description: '',
        description_attachments: '',
      });
    }
  }, [userdetails]);

  useEffect(() => {
    if (
      supportStatus !== null &&
      supportCategory !== null &&
      supportTickets != null &&
      userdetails !== null
    ) {
      const getTecketDetails = supportTickets.results.map((ik: any) => {
        return {
          ...ik,
          email: userdetails.email,
          status: supportStatus.find((ikk: any) => {
            return ikk.id === ik.status;
          }),
          category: supportCategory.find((ikkk: any) => {
            return ikkk.id === ik.category;
          }),
        };
      });
      setTicketData(getTecketDetails);
      setSupportCategoryList(supportCategory)
    }
  }, [supportStatus, supportCategory, supportTickets, userdetails]);

  let initialSteps = 0;
  let StoreSteps = [
    {
      title: t('intro.support.first.title'),
      position: 'center',
      intro: t('intro.support.first.desc'),
    },
    {
      title: t('intro.support.two.title'),
      element: '.support-first',
      position: 'left',
      intro: t('intro.support.two.desc'),
    },
    {
      title: t('intro.support.three.title'),
      element: '.support-second',
      position: 'left',
      intro: t('intro.support.three.desc'),
    },
  ];

  useEffect(() => {

    if(Object.keys(projects).length > 0 && params.get('p_id') !== null && params.get('env_id') !== null && params.get('action') !== null){
      if(params.get('action') === 'deploy'){
        const projectsEnv = projects.find((i: any) => {
          return i.id === parseInt(params.get('p_id') as any);
        });
        console.log("projectsEnv",projectsEnv,)
        if(projectsEnv !== undefined){
          setProjectId(parseInt(params.get('p_id') as any))
          setProjectEnv(projectsEnv);
          const filteredEnv = projectsEnv.meta.find((i: any) => {
            return i.meta.id === parseInt(params.get('env_id') as any);
          });
          if (filteredEnv !== undefined) {
            setEnvId(parseInt(params.get('env_id') as any))
            setSelectedEnv(filteredEnv);
            setSupportToggle(true)
            setSupportCateId(6)
          }
        }
      }
    }

  },[params.get('p_id'),params.get('env_id'),projects])

  useEffect(() => {
    if (localStorage.getItem('introjs') === null) {
      const array = [];
      const homeScreen = { screen: 'support-screen', status: 'true' };
      array.push(homeScreen);
      localStorage.setItem('introjs', JSON.stringify(array!));
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'support-screen';
      });
      if (index < 0) {
        const homeScreen = { screen: 'support-screen', status: 'true' };
        array.push(homeScreen);
        setStepsEnabled(homeScreen.status === 'true' ? true : false);
        localStorage.setItem('introjs', JSON.stringify(array!));
      } else {
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    }
  }, []);

  const onIntroExit = (e: any): void => {
    if (e === -1) {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'support-screen';
      });
      if (index >= 0) {
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'support-screen';
      });
      if (index >= 0) {
        array[index].status = 'false';
        setStepsEnabled(array[index].status === 'false' ? false : true);
        localStorage.setItem('introjs', JSON.stringify(array!));
      }
    }
  };

  const onActivateIntro = () => {
    const getLocal = localStorage.getItem('introjs');
    if (getLocal !== null) {
      const getJson = JSON.parse(getLocal);
      const getIndex = getJson.findIndex((i: any) => {
        return i.screen === 'support-screen';
      });

      if (getIndex > -1) {
        getJson[getIndex].status = 'true';
        setStepsEnabled(true);
        localStorage.setItem('introjs', JSON.stringify(getJson));
      }
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (event.target.files !== null) {
      if (event.target.files?.length > 0) {
        setFilename((event.target as any).files[0].name);
        setFile((event.target as any).files[0]);
        setFileUpload(true);
        // console.log(localStorage.getItem("dynamo"))
        const formData = new FormData();
        formData.append('file', (event.target as any).files[0]);
        const a: any = localStorage.getItem('dynamo') !== null ? JSON.parse(localStorage.getItem('dynamo')!) : [];
        try {
          const ab = await axios({
            url: `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp-json/supportcandy/v2/attachments`,
            method: 'Post',
            data: formData,
            auth: {
              username: wordpressLoginUrl.data.user_name,
              password: wordpressLoginUrl.data.app_name,
            },
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          if (ab) {
            a.push(ab.data);
            localStorage.setItem('dynamo', JSON.stringify(a));
            setFileUpload(false);
            setListFile(a);
          }
        } catch (err) {
          toast.error(`Something went wrong!!!`)
          setFileUpload(false);
          console.log(err);
        }
      }
    }
  };

  const SubmitTicket = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    if (localStorage.getItem('dynamo') !== null) {
      const files = JSON.parse(localStorage.getItem('dynamo')!).map((ik: any) => {
        return ik.id;
      });

      const result = files.reduce((acc: any, current: any, index: number) => {
        acc += current;
        if (index !== files.length - 1) {
          acc += ',';
        }

        return acc;
      }, '');
      const ab = {
        name: userDetails.name,
        email: userDetails.email,
        subject: userDetails.subject,
        description: userDetails.description,
        description_attachments: result,
        category:supportCateId,
        cust_28:projectEnv.id,
        cust_29:selectedEnv.meta.id,
        cust_27:healthInfo
      };
      try {
        const abc = await axios({
          url: `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp-json/supportcandy/v2/tickets`,
          method: 'Post',
          data: ab,
          auth: {
            username: wordpressLoginUrl.data.user_name,
            password: wordpressLoginUrl.data.app_name,
          },
        });
        if (abc) {
          // console.log("abc",abc.data)
          toast.success(`ticket generated successfully`)
          dispatch(fetchSupportTickets({...wordpressLoginUrl,email:userdetails.email}));
          setUserDetails({
            subject: '',
            description: '',
            description_attachments: '',
          })
          setSupportCateId(0)
          localStorage.removeItem('dynamo');
        }
      } catch (err) {
        localStorage.removeItem('dynamo');
        toast.error(`Something went wrong`)
        setUserDetails({
          subject: '',
          description: '',
          description_attachments: '',
        })
        setSupportCateId(0)
        console.log(err);
      }
      // console.log(ab,"abbbbbbbbbb")
    }
  };

const selectProjects = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const noSelectProject = event.target.value;
    if (noSelectProject === '') {
      setSelectedEnv({});
      setProjectEnv({});
      setProjectId(0)
      setEnvId(0)
    } else {
      const filteredEnv = projects.find((i: any) => {
        return i.id === parseInt(event.target.value);
      });
      if (filteredEnv !== undefined) {
        setProjectEnv(filteredEnv);
        setProjectId(filteredEnv.id)
        setSelectedEnv({});
      }
    }
  };


const selectEnv = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const noSelectEnv = event.target.value;
    if (noSelectEnv === '') {
      setSelectedEnv({});
    } else {
      const filteredEnv = projectEnv.meta.find((i: any) => {
        return i.meta.id === parseInt(event.target.value);
      });
      if (filteredEnv !== undefined) {
        setSelectedEnv(filteredEnv);
        setEnvId(filteredEnv.meta.id)
      }
    }
  };

  const validateForm = () => {
    let newError: any = {};

    const isValidName = (str: any) => {
      return str && str.length > 0;
    };

    const isValidEmail = (str: any) => {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return str && emailRegex.test(str);
    };

    const isValidSubject = (str: any) => {
      return str && str.trim().length > 0;
    };

    const isValidDescription = (str:any) =>{
      return str && str.trim().length > 0;
    }

    const isValidProject = (projectId: any) => {
      return projectId && projectId !== '';
    };

    const isValidEnvironment = (envId: any) => {
      return envId && envId !== '';
    };
  
    if (!isValidName(userDetails.name)) {
      newError.name = 'Name is required.';
    }
  
    if (!isValidEmail(userDetails.email)) {
      newError.email = 'Email is required and must be a valid email address.';
    }
  
    if (!isValidSubject(userDetails.subject)) {
      newError.subject = 'Subject is required.';
    }

    if (!isValidDescription(userDetails.description)) {
      newError.description = 'Description is required.';
    }

    if (!isValidProject(projectId)) {
      newError.project = 'Please select a project.';
    }

    if (!isValidEnvironment(envId)) {
      newError.environment = 'Please select an environment.';
    }
  
    if (supportCategoryList.length === 0) {
      newError.supportCategory = 'Support categories are loading, please wait.';
    } else if (!supportCateId) {
      newError.supportCateId = 'Please select a support category.';
    }
    setFormError(newError);
    return Object.keys(newError).length === 0;
  };
  


  if (userdetails === null && isLoading) {
    return <Loader />;
  }

  if(isError){
    return <ErrorPage/>
  }

  return (
    <>
      {pathname === '/support' && (
        <Steps
          enabled={stepsEnabled}
          steps={StoreSteps}
          onExit={e => onIntroExit(e)}
          initialStep={initialSteps}
          options={{
            showProgress: true,
          }}
        />
      )}
      <main className='main'>
        <div className='container-fluid'>
          <button
            type='button'
            className='roundedButton d-flex align-items-center me-2 btn_right'
            onClick={() => onActivateIntro()}
          >
            <FontAwesomeIcon icon={faQuestion} />
          </button>
          <div className='store_listab'>
            <ul className='nav nav-tabs store-first store-first ' id='myTab' role='tablist'>
              <li className='nav-item m-0' role='presentation'>
                <button
                  className='nav-link active '
                  id='lists-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#lists'
                  type='button'
                  role='tab'
                  aria-controls='lists'
                  aria-selected='true'
                  onClick={handleShow}
                >
                  {t('support.Generate-ticket')}
                </button>
              </li>
              <li className='nav-item m-0' role='presentation'>
                <button
                  className='nav-link'
                  id='team-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#team'
                  type='button'
                  role='tab'
                  aria-controls='team'
                  aria-selected='false'
                  onClick={handleShow}
                >
                  {t('support.Overview')}
                </button>
              </li>
            </ul>
            <div className='tab-content'>
              <div className='tab-pane active' id='lists' role='tabpanel' aria-labelledby='lists-tab'>
                <div className='py-3'>
                  <form className='addproject' onSubmit={e => { e.preventDefault(); if (validateForm()) { SubmitTicket(e); } }}>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>{t('support.Name')}</span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter name'
                        name='name'
                        value={userDetails.name}
                        onChange={e => setUserDetails({ ...userDetails, name: e.target.value })}
                      />
                       {formError.name && <div className="error-text">{formError.name}</div>}
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>{t('support.Email')}</span>
                      <input
                        type='email'
                        className='form-control'
                        placeholder='Enter email'
                        name='email'
                        value={userDetails.email}
                        onChange={e => setUserDetails({ ...userDetails, email: e.target.value })}
                      />
                    </div>
                    {formError.email && <div className="error-text text-danger d-block">{formError.email}</div>}
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>{t('support.Subject')}</span>
                      <textarea
                        className='form-control'
                        placeholder='write description here'
                        name='issue'
                        value={userDetails.subject}
                        onChange={e => setUserDetails({ ...userDetails, subject: e.target.value })}
                      />
                    </div>
                    {formError.subject && <div className="error-text text-danger d-block">{formError.subject}</div>}

                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Project</span>
                      <select
                      className='environment-select form-select'
                      value={projectId}
                      // style={{ backgroundColor: '#202020', color: 'white' }}
                      onChange={e => selectProjects(e)}
                    >
                      <option value=''>{t('store-list.queue.Select-Project')}</option>
                      {typeof projects !== 'undefined' &&
                        projects.length > 0 &&
                        projects.map((ik: any) => {
                          return <option value={ik.id}>{ik.slug}</option>;
                        })}
                    </select>
                    </div>
                    {formError.projectId && <div className="error-text text-danger">{formError.projectId}</div>}
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Environment</span>
                      <select
                      className='environment-select form-select'
                      style={{color: 'white' }}
                      value={envId}
                      onChange={e => selectEnv(e)}
                    >
                      <option value=''>{t('store-list.queue.Select-Environment')}</option>
                      {Object.keys(projectEnv).length > 0 &&
                      Object.prototype.hasOwnProperty.call(projectEnv, 'meta') &&
                      projectEnv.meta &&
                      projectEnv.meta.length > 0
                        ? projectEnv.meta.map((ik: any) => (
                            <option key={ik.meta.id} value={ik.meta.id}>
                              {ik.meta.name}
                            </option>
                          ))
                        : null}
                      {/* {Object.keys(projectEnv).length > 0 ? (
                Object.prototype.hasOwnProperty.call(projectEnv, 'meta') ? (
                  projectEnv.meta.map((ik: any) => {
                  return <option value={ik.meta.id}>{ik.meta.name}</option>;
                  })

                ) : null
              ) : null} */}
                    </select>
                    </div>
                    {formError.envId && <div className="error-text text-danger">{formError.envId}</div>}
                    {/* <div className='input-group mb-3'>
                    <span className='input-group-text'>Project</span>
                    <select className='form-select' id='projectselect' name='project'>
                      <option value='0'>Select project</option>
                      <option value='1'>Project 1</option>
                      <option value='2'>Project 2</option>
                      <option value='3'>Project 3</option>
                    </select>
                  </div> */}
                    {/* <div className='input-group mb-3'>
                    <span className='input-group-text'>Type</span>
                    <select className='form-select' id='typeselect' name='type'>
                      <option value='0'>Select type</option>
                      <option value='plugin'>Plugin</option>
                      <option value='theme'>Theme</option>
                    </select>
                  </div> */}
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>{t('support.issue-description')}</span>
                      <textarea
                        className='form-control'
                        placeholder='write description here'
                        name='issue'
                        rows={15}
                        value={userDetails.description}
                        onChange={e => setUserDetails({ ...userDetails, description: e.target.value })}
                      />
                    </div>
                    {formError.description && <div className="error-text text-danger">{formError.description}</div>}
                    {supportCategoryList.length === 0 ?
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Loading...</span>
                    </div>
                    :
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Support Category</span>
                      <select className='form-select bg-transparent text-white' value={supportCateId} disabled={supportToggle} onChange={(e:any) => setSupportCateId(parseInt(e.target.value))}>
                        <option value=''>Select Category</option>
                        {supportCategoryList.map((ik:any) => {
                          return (
                            <option value={ik.id}>{ik.name}</option>
                          )
                        })}

                      </select>
                      {formError.supportCateId && <div className="error-text text-danger">{formError.supportCateId}</div>}
                    </div>
                    }
                    <div>
                      <p>For Wordpress Health Report <Link style={{color:"#f08921"}} to="/wp-site-info" target="_blank">click here!</Link></p>
                      </div>
                      <div className='input-group mb-3'>
                      <span className='input-group-text'>Health info</span>
                      <textarea
                        className='form-control'
                        placeholder='write description here'
                        name='issue'
                        rows={15}
                        value={healthInfo}
                        onChange={e => setHealthInfo(e.target.value)}
                      />
                    </div>

                    <div className='d-flex mb-3'>
                      <input
                        className='form-control d-none'
                        type='file'
                        id='formFile'
                        onChange={e => handleFileChange(e)}
                      />
                      <input className='form-control' type='text' value={filename} />
                      {fileUpload !== true && (
                        <label htmlFor='formFile' className='ms-2 cblue_btn'>
                          {t('support.Browse')}
                        </label>
                      )}
                    </div>
                    <div className='text-white'>
                      {listFile.map(ik => {
                        return (
                          <>
                            <span className='text-white'>{ik.name}</span>
                            <br />
                          </>
                        );
                      })}
                    </div>
                    <div className='d-flex justify-content-end my-5 '>
                      <button type='submit' className='orange_btn mx-1 support-second'>
                        {t('support.Generate-ticket')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className='tab-pane' id='team' role='tabpanel' aria-labelledby='team-tab'>
                {ticketData.length ? <TicketList supportstatus={supportStatus} ticketData={ticketData} wordpressLoginUrl={wordpressLoginUrl} /> : <Loader />}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Suports;
