import { useState, useCallback } from 'react';
import axios from 'axios';

interface UserDetails {
  email: string;
  displayName: string;
}

// interface LoginResult {
//   success: boolean;
//   redirectUrl?: string;
//   error?: string;
// }

const useWordPressLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [result, setResult] = useState<any | null>(null);

  const loginToWordPress = useCallback(
    async (userdetails: UserDetails, token: string): Promise<any | null> => {
      setLoading(true);
      setError(null);
      // console.log("userdetails",userdetails)

      try {
        const formData = new FormData();
        formData.append('Email', userdetails.email);
        formData.append('token', token);

        const response = await axios.post(
          `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp-json/signin-user/myaccount`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        const redirectUrl = response.data;
        console.log("redirectUrl",redirectUrl)

        if (redirectUrl?.success === false) {
          const email = userdetails.email;
          const atIndex = email.indexOf('@');
          if (atIndex !== -1) {
            const emailBeforeAt = email.substring(0, atIndex);

            await axios.post(
              `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp/user-sync.php`,
              {},
              {
                params: {
                  action: 'get_user_data',
                  fname: userdetails.displayName,
                  lname: userdetails.displayName,
                  username: emailBeforeAt,
                  email: userdetails.email,
                  role: 'customer',
                },
              }
            );
          }
        }

        setResult(redirectUrl);
        return redirectUrl;
      } catch (err: any) {
        console.error('Error during login:', err);
        setError(err.message || 'An error occurred');
        setResult({ success: false, error: err.message || 'An error occurred' });
        return { success: false, error: err.message || 'An error occurred' };
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return { loginToWordPress, loading, error, result };
};

export default useWordPressLogin;
