/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { SetStateAction, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../src/sass/pages/subscriptions.scss';
import logo from '../img/home_log.png';
// import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CommonModal from './CommonModal';
// import { Modal } from 'bootstrap'

interface SubscriptionModalProps {
  subscription_status: string;
  setIsModalOpen: React.Dispatch<SetStateAction<boolean>>;
  isModalOpen: boolean;
  children: React.ReactNode;
  timeRemaining: string;
  outsideClick: boolean;
  wordpressLoginUrl: any;
  trial_end_date?: any;
  // header:React.ReactNode
}

function useOutsideClick(ref: any, handler: any, outsideClick: boolean) {
  useEffect(() => {
    const listener = (event: any) => {
      if (outsideClick === true) {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      }
      return;
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
}

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({
  subscription_status,
  setIsModalOpen,
  isModalOpen,
  children,
  timeRemaining,
  outsideClick,
  wordpressLoginUrl,
  trial_end_date,
}) => {
  const navigate = useNavigate();
  const modalRef: any = useRef();
  useEffect(() => {
    // Ensure modal is appended to body when it is open
    if (isModalOpen) {
      document.body.classList.add('modal-open'); // Add Bootstrap's modal-open class to body
      document.body.setAttribute('data-rr-ui-modal-open', '');
      // const a:any = document.createElement("div")
      // console.log("a",a)
      // a.classList.add('modal-backdrop','show')
      // document.body.append(a)
    } else {
      document.body.classList.remove('modal-open'); // Remove modal-open class when modal is closed
      document.body.removeAttribute('data-rr-ui-modal-open');
    }
  }, [isModalOpen]);

  const handleSubscribe = () => {
    console.log('hello');
    // handleClose(); // Close modal after subscribe action
    window.open(
      `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/sp/sign-up?loginu=${wordpressLoginUrl.data.login_id}`,
      '_blank'
    );
  };
  const { t } = useTranslation();
  const handleClose = () => {
    setIsModalOpen(false);
  };
  const handleSubscribeClick = () => {
    handleSubscribe();
    handleClose(); // Close modal after subscribe action
  };

  useOutsideClick(modalRef, handleClose, outsideClick);

  return (
    <>
      {isModalOpen ? <div className='modal-backdrop show'></div> : null}
      {/* <div role='dialog' className={` modal fade ${isModalOpen ? 'show':'hide'}`} style={{ display: isModalOpen ? 'block' : 'none' }} tabIndex={-1}>
      <div className="modal-dialog modal-lg">
        <div className=" modal-content composer-modal">
          <div className="modal-body">
            <div className="logo" style={{ color: 'white' }}>
              <img src={logo} alt="Logo" style={{ width: '200px', height: '100px' }} />
              <h5>{t('subscription-Model-Text')}</h5>
              <h6>👉🏻 {t('subscription-Model-Sub-text')}{subscription_status} 🪧 </h6>
              <button className="btn btn-primary orange_btn" onClick={handleSubscribeClick}>
                {t('subscription-Model-Button')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div> */}

      {/* <div role='dialog'
              style={{ display: isModalOpen ? 'block' : 'none' }}
                className={`composer-model modal fade ${isModalOpen ? 'show':'hide'}`}
                id="subscriptionModal"
                tabIndex={-1}
                aria-labelledby="subscriptionModalLabel"
                aria-hidden="true"
            >
                <div className="composer-model modal-dialog modal-lg" ref={modalRef}>
                    <div className="modal-content composer-model">
                      <div className='modal-header'>
                        {children}
                      </div>
                        <div className="modal-body">
                          {children}
                            <div className="logo" style={{ color: "white" }}>
                                <img src={logo} alt="Logo" style={{ width: '200px', height: '100px' }} />
                                <h5>{t('subscription-Model-Text')}</h5>
                                <h6>👉🏻 {t('subscription-Model-Sub-text')} {subscription_status} 🪧 </h6>
                                <button className="btn btn-warning" onClick={handleSubscribe}>
                                    {t('subscription-Model-Button')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      <CommonModal
        className='composer-model'
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        animation={false}
        size='lg'
        outsideClick={outsideClick}
      >
        <CommonModal.Body>
          <div className='logo' style={{ color: 'white' }}>
            <img src={logo} alt='' style={{ width: '200px', height: '100px' }} />
            <h5>{t('subscription-Model-Text')}</h5>
            <h6>
              👉🏻 {t('subscription-Model-Sub-text')}
              {subscription_status}{' '}
            </h6>
            {subscription_status === 'active' && <h6>🕒 Your trail period is end on {trial_end_date}</h6>}
            {/* <h6>To start trail period click on subscribe</h6> */}
            <button className='orange_btn' onClick={handleSubscribe}>
              {t('subscription-Model-Button')}
            </button>
          </div>
        </CommonModal.Body>
      </CommonModal>
      {/* <div className="modal fade show" tabIndex={-1} role="dialog" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content bg-dark text-light" style={{ backgroundColor: 'black', color: 'white', width: '700px'}}>
            <div className="modal-body">
              <div className="logo">
                <img src={logo} alt="" style={{ width: '200px', height: '100px' }} />
                <h5>Empower Your FusePress Journey: The Perfect Plan for Every Need</h5>
                <h6>👉🏻 Your current subscription status: {subscription_status} 🪧 </h6>
                <button onClick={handleSubscribe}>Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default SubscriptionModal;
