import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import axios from 'axios'
import { toast } from 'react-toastify';
import CryptoJS from 'crypto-js';

function WPSiteInfo() {

  const secretKey = "piUG5346UOYBD34567EOYGb567fyusHFogeyfbhd457bFGYUfeuyoeg8732659thgc65858f8FT7r6732ruyefFUF65ru7634"

  const [sshData, setSshData] = useState<any>({
    url: '',
    username: '',
    password: '',
    premium_username: '',
    premium_password: '',
    path_to_wp_installation: '',
    path_to_wp_content_folder: '',
    private_key: '',
    paraphrase: '',
    subtype: '',
    meta: '',
    text: '',
  });
  const [sshError, setSshError] = useState<any>({});
  const [checkboxSsh, setCheckboxSsh] = useState<boolean>(false);
  const [loaderBtn, setLoaderBtn] = useState<boolean>(false)

  const { t } = useTranslation()

  const validateSsh = () => {
    let newError: any = {};

    // const containsRequiredCharsForPremiumPassword = (str: any) => {
    //   return str && str.length >= 16 && /[A-Za-z]/.test(str) && /\d/.test(str);
    // };

    const containsRequiredCharsForPassword = (str: any) => {
      return str && str.length > 4 && /[A-Za-z]/.test(str);
    };

    // const isValidPremiumUsername = (str: any) => {
    //   return str && str.length >= 8 && /^[a-z0-9]+$/.test(str);
    // };

    const isValidUser = (str: any) => {
      return str && str.length > 0 && /^[a-zA-Z0-9]+$/.test(str);
    };

    const isValidPath = (path: any) => {
      const pathRegex = /^(\/[^\/ ]*)+\/?$/;
      return pathRegex.test(path);
    };

    const isValidUrl = (url: any) => {
      const ipRegex =
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      return url && (url.startsWith('http://') || url.startsWith('https://') || ipRegex.test(url));
    };

    if (!isValidUrl(sshData.url)) {
      newError.url = 'Url is required and must be a valid URL or IP address.';
    }
    if (!isValidUser(sshData.username)) {
      newError.username = 'Username is required, must be alphabetic and cannot contain digits.';
    }
    if (checkboxSsh === false && !containsRequiredCharsForPassword(sshData.password)) {
      newError.password = 'Password is required, must be alphanumeric, and contain at least one digit.';
    }
    if (!sshData.path_to_wp_installation || !isValidPath(sshData.path_to_wp_installation)) {
      newError.path_to_wp_installation = 'Valid path to wp installation is required.';
    }
    // if (!sshData.path_to_wp_content_folder || !isValidPath(sshData.path_to_wp_content_folder)) {
    //   newError.path_to_wp_content_folder = 'Valid path to wp-content folder is required.';
    // }
    if (checkboxSsh === true && (!sshData.private_key || !/^-{0,}BEGIN\s+[A-Z\s]+-----/.test(sshData.private_key))) {
      newError.private_key = 'Invalid Private key';
    }
    // if(checkboxSsh === true && (!sshData.paraphase)){
    //   newError.paraphase = "Please Enter Valid Paraphase"
    // }
    // if (!sshData.subtype) {
    //   newError.subtype = 'Subtype is required.';
    // }
    // if (!sshData.meta) {
    //   newError.meta = 'Meta is required.';
    // }
    // if (!isValidPremiumUsername(sshData.premium_username)) {
    //   newError.premium_username = 'Enter premium username, must be alphanumeric, and cannot contain digits.';
    // }
    // if (!containsRequiredCharsForPremiumPassword(sshData.premium_password)) {
    //   newError.premium_password = 'Enter premium password, must be alphanumeric, and contain at least one digit.';
    // }
    // Uncomment and adjust this as needed
    // if (!sshData.paraphase) {
    //   newError.paraphase = 'Paraphase is required.';
    // }
    // console.log("newError" ,sshData,checkboxSsh)
    setSshError(newError);
    return Object.keys(newError).length === 0;
  };

  const onProjectConfigStep = async (event: any, type: string) => {
    event.preventDefault()
    if (validateSsh()) {
      toast.info(`Fetching health info please wait...`)
      setLoaderBtn(true)
      const data = {
        host: sshData.url,
        username: sshData.username,
        isPrivateKey: checkboxSsh,
        password: sshData.password,
        privateKey: sshData.private_key,
        passphrase: sshData.paraphrase,
        wp_path: sshData.path_to_wp_installation
      }
      const encryptedData = encryptData(data);
      console.log(encryptedData)
      try {
        const data1 = await axios({
          url: `${process.env.REACT_APP_AUTH_NODE_URL}/api/wp-cli/wp-health-status`,
          method: "POST",
          data: encryptedData,
        })
        if (data1) {
          if (Object.prototype.hasOwnProperty.call(data1.data, 'output')) {
            // console.log(data1.data)

            setLoaderBtn(false)
            var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data1.data, null, 2));
            var dlAnchorElem = document.getElementById('downloadAnchorElem');
            if (dlAnchorElem !== null) {
              dlAnchorElem.setAttribute("href", dataStr);
              dlAnchorElem.setAttribute("download", "wp-health-info.json");
              dlAnchorElem.click();
            }

          }
        }
      } catch (error: any) {
        setLoaderBtn(false)
        console.log(error)
        toast.error(`Something went wrong`)
      }
    }


  }

  const encryptData = (data:any) => {
    const iv = CryptoJS.lib.WordArray.random(16);
    const key = CryptoJS.SHA256(secretKey);
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, { iv });
    return {
      iv: iv.toString(CryptoJS.enc.Base64),
      data: encrypted.toString(),
    };
  };

  return (
    
   <>
 
        <form className='addproject' onSubmit={e => onProjectConfigStep(e, 'ssh')}>
       
          <div className='alert alert-warning' role='alert'>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            &nbsp;&nbsp;{t('deploy.warning-message')}
          </div>
          <div className='input-group mb-3'>
            <span className='input-group-text'>{t('deploy.ssh.address')}</span>
            <input
              type='text'
              className='form-control'
              placeholder='https://'
              name='url'
              value={sshData.url}
              onChange={e => {
                setSshData({
                  ...sshData,
                  url: e.target.value,
                });
                if (sshError.url) {
                  setSshError((prevErrors: any) => ({ ...prevErrors, url: null }));
                }
              }}
            />
          </div>
          {sshError.url && <div style={{ color: 'red', fontSize: '14px' }}>{sshError.url}</div>}
          <div className='input-group mb-3'>
            <span className='input-group-text'>{t('deploy.ssh.user')}</span>
            <input
              type='text'
              className='form-control'
              placeholder='Enter User'
              name='username'
              value={sshData.username}
              onChange={e => {
                setSshData({
                  ...sshData,
                  username: e.target.value,
                });
                if (sshError.username) {
                  setSshError((prevErrors: any) => ({ ...prevErrors, username: null }));
                }
              }}
            />
          </div>
          {sshError.username && <div style={{ color: 'red', fontSize: '14px' }}>{sshError.username}</div>}
          {/* <div className='input-group mb-3'>
                    <span className='input-group-text'>Premium Username</span>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter premium username'
                      name='username'
                      value={sshData.premium_username}
                      onChange={e => {
                        setSshData({
                          ...sshData,
                          premium_username: e.target.value,
                        });
                        if (sshError.username) {
                          setSshError((prevErrors: any) => ({ ...prevErrors, premium_username: null }));
                        }
                      }}
                    />
                  </div>
                  {sshError.premium_username && (
                    <div style={{ color: 'red', fontSize: '14px' }}>{sshError.premium_username}</div>
                  )} */}
          {/* <div className='input-group mb-3'>
                    <span className='input-group-text'>Premium Password</span>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter premium password'
                      name='username'
                      value={sshData.premium_password}
                      onChange={e => {
                        setSshData({
                          ...sshData,
                          premium_password: e.target.value,
                        });
                        if (sshError.username) {
                          setSshError((prevErrors: any) => ({ ...prevErrors, premium_password: null }));
                        }
                      }}
                    />
                  </div>
                  {sshError.premium_password && (
                    <div style={{ color: 'red', fontSize: '14px' }}>{sshError.premium_password}</div>
                  )} */}
          <div
            className='tab-pane fade text-white active show'
            id='pills-composer'
            role='tabpanel'
            aria-labelledby='pills-composer-tab'
          >
            <div className='flex justify-content-start'>
              <div className='login_register' style={{ justifyContent: 'start' }}>
                <span>{t('deploy.ssh.password')}</span>
                <label className='switch'>
                  <input
                    type='checkbox'
                    checked={checkboxSsh}
                    id='select_login_screen'
                    name='select_login_screen'
                    onChange={e => setCheckboxSsh(!checkboxSsh)}
                  />
                  <span className='slider round'></span>
                </label>
                <span>{t('deploy.ssh.public-key')}</span>
              </div>
            </div>
            {!checkboxSsh ? (
              <div>
                <div className='input-group mb-3'>
                  <span className='input-group-text'>{t('deploy.ssh.password')}</span>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Password'
                    name='password'
                    value={sshData.password}
                    onChange={e => {
                      setSshData({
                        ...sshData,
                        password: e.target.value,
                      });
                      if (sshError.password) {
                        setSshError((prevErrors: any) => ({ ...prevErrors, password: null }));
                      }
                    }}
                  />
                </div>
                {sshError.password && <div style={{ color: 'red', fontSize: '14px' }}>{sshError.password}</div>}
                <div className='input-group mb-3'>
                  <span className='input-group-text'>{t('deploy.ssh.path-wordpress-install')}</span>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter path'
                    name='path_to_wp_installation'
                    value={sshData.path_to_wp_installation}
                    onChange={e => {
                      setSshData({
                        ...sshData,
                        path_to_wp_installation: e.target.value,
                      });
                      if (sshError.path_to_wp_installation) {
                        setSshError((prevErrors: any) => ({ ...prevErrors, path_to_wp_installation: null }));
                      }
                    }}
                  />
                </div>
                {sshError.path_to_wp_installation && (
                  <div style={{ color: 'red', fontSize: '14px' }}>{sshError.path_to_wp_installation}</div>
                )}
                {/* <div className='input-group mb-3'>
                          <span className='input-group-text'>{t('deploy.ssh.wp-content-path')}</span>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Enter path'
                            name='path_to_wp_content_folder'
                            value={sshData.path_to_wp_content_folder}
                            onChange={e => {
                              setSshData({
                                ...sshData,
                                path_to_wp_content_folder: e.target.value,
                              });
                              if (sshError.path_to_wp_content_folder) {
                                setSshError((prevErrors: any) => ({
                                  ...prevErrors,
                                  path_to_wp_content_folder: null,
                                }));
                              }
                            }}
                          />
                        </div>
                        {sshError.path_to_wp_content_folder && (
                          <div style={{ color: 'red', fontSize: '14px' }}>{sshError.path_to_wp_content_folder}</div>
                        )} */}
              </div>
            ) : (
              <div>
                <div className='input-group mb-3'>
                  <span className='input-group-text'>{t('deploy.ssh.private-key')}</span>
                  <textarea
                    rows={10}
                    className='form-control'
                    placeholder='Enter path'
                    name='path_to_wp_installation'
                    value={sshData.private_key}
                    onChange={e => {
                      setSshData({
                        ...sshData,
                        private_key: e.target.value,
                      });
                      if (sshError.private_key) {
                        setSshError((prevErrors: any) => ({ ...prevErrors, private_key: null }));
                      }
                    }}
                  ></textarea>
                </div>
                {sshError.private_key && (
                  <div style={{ color: 'red', fontSize: '14px' }}>{sshError.private_key}</div>
                )}
                <div className='input-group mb-3'>
                  <span className='input-group-text'>{t('deploy.ssh.paraphase')}</span>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter your paraphase'
                    name='paraphase'
                    value={sshData.paraphase}
                    onChange={e => {
                      setSshData({
                        ...sshData,
                        paraphase: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className='input-group mb-3'>
                  <span className='input-group-text'>{t('deploy.ssh.path-wordpress-install')}</span>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter path'
                    name='path_to_wp_installation'
                    value={sshData.path_to_wp_installation}
                    onChange={e => {
                      setSshData({
                        ...sshData,
                        path_to_wp_installation: e.target.value,
                      });
                      if (sshError.path_to_wp_installation) {
                        setSshError((prevErrors: any) => ({ ...prevErrors, path_to_wp_installation: null }));
                      }
                    }}
                  />
                </div>
                {sshError.path_to_wp_installation && (
                  <div style={{ color: 'red', fontSize: '14px' }}>{sshError.path_to_wp_installation}</div>
                )}
                {/* <div className='input-group mb-3'>
                          <span className='input-group-text'>{t('deploy.ssh.wp-content-path')}</span>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Enter path'
                            name='path_to_wp_content_folder'
                            value={sshData.path_to_wp_content_folder}
                            onChange={e => {
                              setSshData({
                                ...sshData,
                                path_to_wp_content_folder: e.target.value,
                              });
                              if (sshError.path_to_wp_content_folder) {
                                setSshError((prevErrors: any) => ({
                                  ...prevErrors,
                                  path_to_wp_content_folder: null,
                                }));
                              }
                            }}
                          />
                        </div>
                        {sshError.path_to_wp_content_folder && (
                          <div style={{ color: 'red', fontSize: '14px' }}>{sshError.path_to_wp_content_folder}</div>
                        )} */}
              </div>
            )}
          </div>

          <div className='d-flex justify-content-end my-5'>
            {/* <button className='orange_btn mx-1' onClick={handleModal}>{t('deploy.ssh.buttons.deploy')}
                    </button> */}
            <button className='orange_btn mx-1' disabled={loaderBtn}>{loaderBtn ? 'Loader ..' :t('deploy.ssh.buttons.deploy')}</button>
          </div>
        </form>
     
      <a id="downloadAnchorElem" style={{ display: "none" }}></a>
   </>
  )
}

export default WPSiteInfo
