import { createSlice } from '@reduxjs/toolkit';

const storePluginSlice = createSlice({
  name: 'store_plugins',
  initialState: {
    items: [],
  },
  reducers: {
    addToStorePluginItem: (state:any, action:any) => {
      const item:any = action.payload;
      // Avoid adding duplicate items
      if (!state.items.find((i:any) => (i.id === item.id))) {
        state.items.push(item);
      }
    },
    removeFromStorePlugin: (state, action) => {
      const item = action.payload;
      state.items = state.items.filter((i:any) => i.id !== item.id);
    },
    // removespecificlist:(state,action:any) => {
    //   state.items = state.items.filter((i:any) => !(i.dataType === action.payload.dataType));
    // },
    clearStorePlugin: (state) => {
      state.items = [];
    },
  },
});

export const { addToStorePluginItem, removeFromStorePlugin, clearStorePlugin } = storePluginSlice.actions;

export default storePluginSlice.reducer;
