import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';

interface TicketListProps {
  ticketData: any;
  wordpressLoginUrl:any
  supportstatus:any
}

const TicketList: React.FC<TicketListProps> = ({ ticketData,wordpressLoginUrl,supportstatus }: TicketListProps) => {




  const [statusfilter, setstatusFilter] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event: { target: { value: string; }; }) => {
    setSearchQuery(event.target.value.toLowerCase());
  };
  const handleFilterChange = (event: { target: { value: any; }; }) => {
    setstatusFilter(event.target.value);
  };

  // const filteredData = ticketData.filter((ticket: { status: { name: string; }; }) => {
  //   if (statusfilter === 'all') return true;
  //   return ticket.status.name.toLowerCase() === statusfilter.toLowerCase();
  // });

  const filteredData = () => {
    return ticketData.filter((ticket: { status: { name: string; }; subject: string; }) => {
      const matchesStatus =
        statusfilter === 'all' ||
        ticket.status.name.toLowerCase() === statusfilter;
      const matchesSearch =
        searchQuery === '' ||
        ticket.subject.toLowerCase().includes(searchQuery);
      return matchesStatus && matchesSearch;
    });
  };

  console.log(ticketData,"sssssssssssssssssssssssssssssss")

  const navigate = useNavigate()
  
  const visitTicket = (item: any): void => {
    // console.log('item',item)
    console.log('wordpressLoginUrl.data.id',wordpressLoginUrl.data.login_id)
    // navigate(`/ticket/${item.id}`)
    const newWindow = window.open(
      `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/support?loginu=${wordpressLoginUrl.data.login_id}&wpsc-section=ticket-list&ticket-id=${item.id}`,
      '_blank'
    );
    if (newWindow) {
      newWindow.focus();
    } else {
      // Popup blocked, handle the error or notify the user
      console.error('Popup blocked!');
    }
  };
  const formatDateTime = (dateString: string | number | Date) => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short', // "Wed"
      day: '2-digit',   // "30"
      month: 'short',   // "Dec"
      year: 'numeric',  // "2024"
      hour: '2-digit',  // "06"
      minute: '2-digit', // "55"
      hour12: true,      // AM/PM format
    };
  
    const date = new Date(dateString); // Convert to Date object

    return new Intl.DateTimeFormat('en-US', options).format(date);
  };



  return (
    <>
      <div className='row align-items-center'>
        <div className='col'>
          <div className='page_title mt-4 mb-4 ms-3 secondary-color'>
          <div className='form-check form-check-inline'>
        <input
          className='form-check-input'
          type='radio'
          name='inlineRadioOptions'
          id='inlineRadio1'
          value='all'
          checked={statusfilter === 'all'}
          onChange={handleFilterChange}
        />
        <label className='form-check-label' htmlFor='inlineRadio1'>All</label>
      </div>


{supportstatus.map((status:any) => (
        <div className='form-check form-check-inline' key={status.id}>
          <input
            className='form-check-input'
            type='radio'
            name='inlineRadioOptions'
            id={`inlineRadio${status.id}`}
            value={status.name.toLowerCase()}
            checked={statusfilter === status.name.toLowerCase()}
            onChange={handleFilterChange}
          />
          <label
            className='form-check-label'
            htmlFor={`inlineRadio${status.id}`}
      
          >
            {status.name}
          </label>
        </div>
      ))}
          </div>
        </div>
        <div className='col-auto'>
          <div className='search'>
          <div className='search_main'>
        <input
          type='text'
          placeholder='Search here'
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <svg
          aria-hidden='true'
          focusable='false'
          data-prefix='fas'
          data-icon='magnifying-glass'
          className='svg-inline--fa fa-magnifying-glass'
          role='img'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 512 512'
        >
          <path
            fill='#6DB1BC'
            d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z'
          ></path>
        </svg>
      </div>
          </div>
        </div>
      </div>

      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>Id</th>
              <th scope='col'>Email</th>
              <th scope='col'>Subject</th>
              <th scope='col'>Category</th>
              {/*  */}
              <th scope='col'>Last update date</th>
              <th scope='col'>Status</th>
              <th scope='col'>View Details</th>
            </tr>
          </thead>
          <tbody>
            {filteredData().map((ik: any) => {
              return (
                <tr>
                  <td>{ik.id}</td>
                  <td>{ik.email}</td>
                  <td>{ik.subject}</td>
                  <td>{ik.category.name}</td>

                  <td>{formatDateTime(ik.date_created)}</td>

                  <td>
                    <span
                      className='ticket_status'
                      style={{
                        backgroundColor: ik.status.bg_color,
                        color: ik.status.color,
                        width: 'auto',
                        padding: '5px 10px',
                        borderRadius: '10px',
                      }}
                    >
                      {ik.status.name}
                    </span>
                  </td>
                  <td>
                    <button type='button' className='cblue_btn' onClick={() => visitTicket(ik)}>
                      Visit Ticket
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TicketList;
