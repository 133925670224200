/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { SetStateAction, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../src/sass/pages/subscriptions.scss';
import { useTranslation } from 'react-i18next';

interface CommonModalProps {
  subscription_status?: string;
  onHide: React.Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
  show?: boolean;
  className?: string;
  animation?: boolean;
  size?: string;
  fullscreen?: boolean;
  outsideClick?: boolean;
}

interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  closeButton?: boolean;
}

function useOutsideClick(ref: any, handler: any, outsideClick: boolean) {
  useEffect(() => {
    const listener = (event: any) => {
      if (outsideClick === true) {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      }
      return;
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
}

const CommonModal: React.FC<CommonModalProps> & {
  Header: React.FC<HeaderProps>;
  Footer: React.FC<React.HTMLAttributes<HTMLDivElement>>;
  Body: React.FC<React.HTMLAttributes<HTMLDivElement>>;
} = ({ subscription_status, onHide, show, children, outsideClick }) => {
  const navigate = useNavigate();
  const modalRef: any = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (show) {
      document.body.classList.add('modal-open');
      document.body.setAttribute('data-rr-ui-modal-open', '');
    } else {
      document.body.classList.remove('modal-open');
      document.body.removeAttribute('data-rr-ui-modal-open');
    }
  }, [show]);

  const handleSubscribe = () => {
    window.open('https://fusepress.co/checkouts/checkout/', '_blank');
  };

  const handleClose = () => {
    onHide(false);
  };

  const handleSubscribeClick = () => {
    handleSubscribe();
    handleClose();
  };

  useOutsideClick(modalRef, handleClose, typeof outsideClick !== 'undefined' ? outsideClick : true);

  let header: React.ReactNode;
  let footer: React.ReactNode;
  let body: React.ReactNode;

  React.Children.forEach(children, child => {
    if (React.isValidElement(child)) {
      if (child.type === CommonModal.Header) {
        header = child;
      } else if (child.type === CommonModal.Footer) {
        footer = child;
      } else {
        body = child;
      }
    }
  });

  return (
    <>
      {show ? <div className='modal-backdrop show'></div> : null}

      <div
        role='dialog'
        style={{ display: show ? 'block' : 'none' }}
        className={`composer-model modal fade ${show ? 'show' : 'hide'}`}
        id='commonModal'
        tabIndex={-1}
        aria-labelledby='commonModalLabel'
        // aria-hidden='true'
      >
        <div className='composer-model modal-dialog modal-lg' ref={modalRef}>
          <div className='modal-content composer-model' style={{ backgroundColor: '#202020' }}>
            {header && (
              <div className='modal-header'>
                {header}
                {/* {(header as React.ReactElement<HeaderProps>).props.closeButton && (
                )} */}
              </div>
            )}
            <div className='modal-body'>{body}</div>
            {footer && <div className='modal-footer'>{footer}</div>}
          </div>
        </div>
      </div>
    </>
  );
};

CommonModal.Header = ({ children, closeButton, ...props }: HeaderProps) => (
  <div {...props}>
    {children}
    {/* {closeButton && <Button variant="close" aria-label="Close" />} */}
  </div>
);
CommonModal.Footer = ({ children, ...props }) => <div {...props}>{children}</div>;
CommonModal.Body = ({ children, ...props }) => <div {...props}>{children}</div>;

export default CommonModal;
