import { createSlice } from '@reduxjs/toolkit';
import { api } from './api';

type AuthState = {
  token: string;
  otp: 'true' | 'false'
};

const initialState: AuthState = {
  token: '',
  otp: 'false'
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: state => {
      state.token = '';
      state.otp = 'false';
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(api.endpoints.login.matchFulfilled, (state, { payload }) => {
        // if ('requires_otp' in payload) {
        //   return;
        // }
        state.token = payload.user.otp_preference === false ? (payload.user.token as string) : '';
        state.otp = payload.user.otp_preference === false ? 'false' : 'true';
      })
      .addMatcher(api.endpoints.authentication.matchFulfilled, (state, { payload }) => {
        state.token = payload.access_token;
        state.otp = payload.user.otp_preference === false ? 'false' : 'true';
      })
      .addMatcher(api.endpoints.userCreate.matchFulfilled, (state, { payload }) => {
        console.log(payload)
        // if (payload.passwordVal && !payload.otp_preference) {
          state.token = payload.otp_preference === false ? payload.token : '';
          state.otp = payload.otp_preference === false ? 'false' : 'true';
        // }
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
